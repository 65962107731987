import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'lazysizes';
import './src/styles/global.css';
import React from 'react';
import AppContext from './src/components/AppContext';

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This tutorial has been updated. ` + `Reload to display the latest version?`
  );

  if (answer === true) {
    window.location.reload();
  }
};

export const wrapRootElement = ({ element }) => {
  return <AppContext>{element}</AppContext>;
};
