import React from 'react';
import CodeBlock from './codeBlock';
import AnchorTag from './anchor';
// import { CDBBtn } from 'cdbreact-pro';
import { preToCodeBlock } from 'mdx-utils';
import {
  SidebarExample1,
  SidebarExample2,
  SidebarExample3,
  SidebarExample4,
  SidebarExample5,
  SidebarExample6,
  SidebarExample7,
  BootstrapSidebarExample1,
  BootstrapSidebarExample2,
  BootstrapSidebarExample3,
  BootstrapSidebarExample4,
  StepperAlert1,
  StepperExample1,
  StepperExample2,
  StepperExample3,
  StepperExample4,
  StepperExample5,
  ButtonExample1,
  ButtonExample2,
  ButtonExample3,
  ButtonExample4,
  ButtonExample5,
  ButtonExample6,
  ButtonExample7,
  ButtonExample8,
  ButtonExample9,
  ButtonExample10,
  ButtonExample11,
  ButtonExample12,
  InputExample1,
  InputExample2,
  InputExample3,
  InputExample4,
  InputExample5,
  FooterExample1,
  FooterExample2,
  FooterExample3,
  FooterExample4,
  FooterExample5,
  FooterExample6,
  FooterExample7,
  ButtonGroupExample1,
  ButtonGroupExample2,
  ButtonGroupExample3,
  ButtonGroupExample4,
  ButtonGroupExample5,
  ButtonGroupExample6,
  ButtonGroupExample7,
  ButtonGroupExample8,
  ButtonGroupExample9,
  ButtonGroupExample10,
  ButtonGroupExample11,
  ButtonGroupExample12,
  ButtonToolbarExample1,
  CardExample1,
  CardExample2,
  CardExample3,
  CardExample4,
  CardExample5,
  CardExample6,
  CardExample7,
  CardExample8,
  CardExample9,
  CardExample10,
  DatePickerExample1,
  Code,
  JsBadges1,
  JsBadges2,
  JsBadges3,
  JsBadges4,
  JsBadges5,
  Alert1,
  Alert2,
  Alert3,
  Accordion,
  Breadcrumb1,
  Checkbox1,
  Checkbox2,
  Checkbox3,
  Carousel1,
  Datatables1,
  Datatables2,
  Box1,
  Box2,
  Box3,
  Box4,
  Box5,
  Animation,
  Collapse1,
  Collapse2,
  Dropdown1,
  Dropdown2,
  Dropdown3,
  Dropdown4,
  Dropdown5,
  Dropdown6,
  Dropdown7,
  Dropdown8,
  Dropdown9,
  Dropdown10,
  Dropdown11,
  Dropdown12,
  Form1,
  Form2,
  Form3,
  Form4,
  Form5,
  Form6,
  Form7,
  Form8,
  Form9,
  Form10,
  Form11,
  Form12,
  Form13,
  Form14a,
  Form15,
  Form16,
  Form17,
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  Icon5,
  Icon6,
  Icon7,
  Inputgroup2,
  Inputgroup1,
  Listgroup1,
  Listgroup2,
  Listgroup3,
  Listgroup4,
  Mask1,
  Mask2,
  Mask3,
  Modal1,
  Modal2,
  Modal3,
  Table1,
  Table2,
  Table3,
  Table4,
  Switch1,
  Switch2,
  Spinner1,
  Spinner2,
  Slider1,
  Select1,
  Accordion2,
  ButtonGroup1,
  ButtonGroup2,
  ButtonGroup3,
  ButtonGroup4,
  ButtonGroup5,
  ButtonGroup6,
  ButtonGroup7,
  ButtonGroup8,
  ButtonGroup9,
  ButtonGroup10,
  ButtonGroup11,
  ButtonGroup12,
  ButtonToolbar1,
  Autocomplete1,
  Iframe1,
  Multiselect1,
  Notification1,
  Notification2,
  Notification3,
  Pane1,
  Pane2,
  Panels1,
  Panels2,
  Panels3,
  Panels4,
  Panels5,
  Radio1,
  Radio2,
  Progress1,
  Progress2,
  Progress3,
  Rating1,
  Select2a,
  Select2b,
  Select2c,
  Select2d,
  Navbar1,
  Navbar2,
  Pagination1,
  Pagination2,
  Pagination3,
  Pagination4,
  Tabs1,
  Tabs2,
  Tabs3,
  Tabs4,
  Timepicker1,
  ProAlertJavaScript,
  ProAlertReact,
  ProAlertAngular,
  StepperJs1,
  StepperJs2,
  StepperJs3
} from '../../../examples/contrast';
import Table from './Table';
import * as components from '../../../examples/contrast';
import {
  ColorPalette1,
  ColorPalette2,
  FormExample2,
  FormExample3,
  FormExample4,
  FormExample5,
  FormExample6,
  GridExample,
  GridExample1,
  GridExample2,
  GridExample3,
  TableExample,
  TableExample1,
  WidthExample1,
  WidthExample2,
  TailwindFixedWidth,
  TailwindPercentWidth,
  TailwindResponseWidth,
  TailwindWidthFull,
  TailwindWidthFlex
} from '../../../example';
import { WindframeCompoenentPreview } from './WindframeComponentPreview';

export default {
  h1: props => (
    <h1 className="heading1" id={props.children.replace(/\s+/g, '').toLowerCase()} {...props} />
  ),
  h2: props => (
    <h2 className="heading2" id={props.children.replace(/\s+/g, '').toLowerCase()} {...props} />
  ),
  h3: props => (
    <h3 className="heading3" id={props.children.replace(/\s+/g, '').toLowerCase()} {...props} />
  ),
  h4: props => (
    <h4 className="heading4" id={props.children.replace(/\s+/g, '').toLowerCase()} {...props} />
  ),
  h5: props => (
    <h5 className="heading5" id={props.children.replace(/\s+/g, '').toLowerCase()} {...props} />
  ),
  h6: props => (
    <h6 className="heading6" id={props.children.replace(/\s+/g, '').toLowerCase()} {...props} />
  ),
  p: props => <p className="paragraph" {...props} />,
  code: CodeBlock,
  a: AnchorTag,
  table: props => <Table {...props} />,
  pre: preProps => {
    const props = preToCodeBlock(preProps);
    if (props) {
      return <Code {...props} />;
    }
    return <pre {...preProps} />;
  },
  wrapper: ({ children }) => <>{children}</>,
  button: ({ children, ...rest }) => <button {...rest}>{children} </button>,
  windframePreview: props => <WindframeCompoenentPreview {...props} />,
  // examples to show in mdx
  sidebarExample1: SidebarExample1,
  sidebarExample2: SidebarExample2,
  sidebarExample3: SidebarExample3,
  sidebarExample4: SidebarExample4,
  sidebarExample5: SidebarExample5,
  sidebarExample6: SidebarExample6,
  sidebarExample7: SidebarExample7,
  bootstrapSidebarExample1: BootstrapSidebarExample1,
  bootstrapSidebarExample2: BootstrapSidebarExample2,
  bootstrapSidebarExample3: BootstrapSidebarExample3,
  bootstrapSidebarExample4: BootstrapSidebarExample4,
  stepperAlert1: StepperAlert1,
  stepperExample1: StepperExample1,
  stepperExample2: StepperExample2,
  stepperExample3: StepperExample3,
  stepperExample4: StepperExample4,
  stepperExample5: StepperExample5,
  buttonExample1: ButtonExample1,
  buttonExample2: ButtonExample2,
  buttonExample3: ButtonExample3,
  buttonExample4: ButtonExample4,
  buttonExample5: ButtonExample5,
  buttonExample6: ButtonExample6,
  buttonExample7: ButtonExample7,
  buttonExample8: ButtonExample8,
  buttonExample9: ButtonExample9,
  buttonExample10: ButtonExample10,
  buttonExample11: ButtonExample11,
  buttonExample12: ButtonExample12,
  inputExample1: InputExample1,
  inputExample2: InputExample2,
  inputExample3: InputExample3,
  inputExample4: InputExample4,
  inputExample5: InputExample5,
  footerExample1: FooterExample1,
  footerExample2: FooterExample2,
  footerExample3: FooterExample3,
  footerExample4: FooterExample4,
  footerExample5: FooterExample5,
  footerExample6: FooterExample6,
  footerExample7: FooterExample7,
  buttonGroupExample1: ButtonGroupExample1,
  buttonGroupExample2: ButtonGroupExample2,
  buttonGroupExample3: ButtonGroupExample3,
  buttonGroupExample4: ButtonGroupExample4,
  buttonGroupExample5: ButtonGroupExample5,
  buttonGroupExample6: ButtonGroupExample6,
  buttonGroupExample7: ButtonGroupExample7,
  buttonGroupExample8: ButtonGroupExample8,
  buttonGroupExample9: ButtonGroupExample9,
  buttonGroupExample10: ButtonGroupExample10,
  buttonGroupExample11: ButtonGroupExample11,
  buttonGroupExample12: ButtonGroupExample12,
  buttonToolbarExample1: ButtonToolbarExample1,
  cardExample1: CardExample1,
  cardExample2: CardExample2,
  cardExample3: CardExample3,
  cardExample4: CardExample4,
  cardExample5: CardExample5,
  cardExample6: CardExample6,
  cardExample7: CardExample7,
  cardExample8: CardExample8,
  cardExample9: CardExample9,
  cardExample10: CardExample10,
  datePickerExample1: DatePickerExample1,
  footerExampleReact1: components.FooterExampleReact1,
  footerExampleReact2: components.FooterExampleReact2,
  footerExampleReact3: components.FooterExampleReact3,
  footerExampleReact4: components.FooterExampleReact4,
  footerExampleReact5: components.FooterExampleReact5,
  footerExampleReact6: components.FooterExampleReact6,
  footerExampleReact7: components.FooterExampleReact7,
  inputExampleBootstrap1: components.InputExampleBootstrap1,
  footerExampleBootstrap1: components.FooterExampleBootstrap1,
  footerExampleBootstrap2: components.FooterExampleBootstrap2,
  footerExampleBootstrap3: components.FooterExampleBootstrap3,
  footerExampleBootstrap4: components.FooterExampleBootstrap4,
  footerExampleBootstrap5: components.FooterExampleBootstrap5,
  footerExampleBootstrap6: components.FooterExampleBootstrap5,
  footerExampleBootstrap7: components.FooterExampleBootstrap7,
  footerExampleBootstrap8: components.FooterExampleBootstrap8,
  footerExampleBootstrap9: components.FooterExampleBootstrap9,
  footerExampleBootstrap10: components.FooterExampleBootstrap10,
  footerExampleBootstrap11: components.FooterExampleBootstrap11,
  footerExampleBootstrap12: components.FooterExampleBootstrap12,
  // formExample: FormExample,
  formExample2: FormExample2,
  formExample3: FormExample3,
  formExample4: FormExample4,
  formExample5: FormExample5,
  formExample6: FormExample6,
  colorPalette: ColorPalette1,
  widthExample1: WidthExample1,
  widthExample2: WidthExample2,
  TailwindFixedWidth: TailwindFixedWidth,
  TailwindPercentWidth: TailwindPercentWidth,
  TailwindResponseWidth: TailwindResponseWidth,
  TailwindWidthFull: TailwindWidthFull,
  TailwindWidthFlex: TailwindWidthFlex,
  colorPalette2: ColorPalette2,
  tableExample: TableExample,
  tableExample1: TableExample1,
  gridExample: GridExample,
  gridExample1: GridExample1,
  gridExample2: GridExample2,
  gridExample3: GridExample3,
  JsBadges1: JsBadges1,
  JsBadges2: JsBadges2,
  JsBadges3: JsBadges3,
  JsBadges4: JsBadges4,
  JsBadges5: JsBadges5,
  Alert1: Alert1,
  Alert2: Alert2,
  Alert3: Alert3,
  Accordion: Accordion,
  Breadcrumb1: Breadcrumb1,
  Checkbox1: Checkbox1,
  Checkbox2: Checkbox2,
  Checkbox3: Checkbox3,
  Carousel1: Carousel1,
  Datatables1: Datatables1,
  Datatables2: Datatables2,
  Box1: Box1,
  Box2: Box2,
  Box3: Box3,
  Box4: Box4,
  Box5: Box5,
  Animation: Animation,
  Collapse1: Collapse1,
  Collapse2: Collapse2,

  Dropdown1: Dropdown1,
  Dropdown2: Dropdown2,
  Dropdown3: Dropdown3,
  Dropdown4: Dropdown4,
  Dropdown5: Dropdown5,
  Dropdown6: Dropdown6,
  Dropdown7: Dropdown7,
  Dropdown8: Dropdown8,
  Dropdown9: Dropdown9,
  Dropdown10: Dropdown10,
  Dropdown11: Dropdown11,
  Dropdown12: Dropdown12,
  Form1: Form1,
  Form2: Form2,
  Form3: Form3,
  Form4: Form4,
  Form5: Form5,
  Form6: Form6,
  Form7: Form7,
  Form8: Form8,
  Form9: Form9,
  Form10: Form10,
  Form11: Form11,
  Form12: Form12,
  Form13: Form13,
  Form14a: Form14a,
  Form15: Form15,
  Form16: Form16,
  Form17: Form17,
  Icon1: Icon1,
  Icon2: Icon2,
  Icon3: Icon3,
  Icon4: Icon4,
  Icon5: Icon5,
  Icon6: Icon6,
  Icon7: Icon7,
  Inputgroup2: Inputgroup2,
  Inputgroup1: Inputgroup1,
  Listgroup1: Listgroup1,
  Listgroup2: Listgroup2,
  Listgroup3: Listgroup3,
  Listgroup4: Listgroup4,
  Mask1: Mask1,
  Mask2: Mask2,
  Mask3: Mask3,
  Modal1: Modal1,
  Modal2: Modal2,
  Modal3: Modal3,
  Table1: Table1,
  Table2: Table2,
  Table3: Table3,
  Table4: Table4,
  Switch1: Switch1,
  Switch2: Switch2,
  Spinner1: Spinner1,
  Spinner2: Spinner2,
  Slider1: Slider1,
  Select1: Select1,
  Accordion2: Accordion2,
  ButtonGroup1: ButtonGroup1,
  ButtonGroup2: ButtonGroup2,
  ButtonGroup3: ButtonGroup3,
  ButtonGroup4: ButtonGroup4,

  ButtonGroup5: ButtonGroup5,
  ButtonGroup6: ButtonGroup6,
  ButtonGroup7: ButtonGroup7,
  ButtonGroup8: ButtonGroup8,
  ButtonGroup9: ButtonGroup9,
  ButtonGroup10: ButtonGroup10,
  ButtonGroup11: ButtonGroup11,
  ButtonGroup12: ButtonGroup12,
  ButtonToolbar1: ButtonToolbar1,
  Autocomplete1: Autocomplete1,
  Iframe1: Iframe1,
  Multiselect1: Multiselect1,
  Notification1: Notification1,
  Notification2: Notification2,
  Notification3: Notification3,
  Pane1: Pane1,
  Pane2: Pane2,
  Panels1: Panels1,
  Panels2: Panels2,
  Panels3: Panels3,
  Panels4: Panels4,
  Panels5: Panels5,
  Radio1: Radio1,
  Radio2: Radio2,
  Progress1: Progress1,
  Progress2: Progress2,
  Progress3: Progress3,
  Rating1: Rating1,
  Select2a: Select2a,

  Select2b: Select2b,
  Select2c: Select2c,
  Select2d: Select2d,
  Navbar1: Navbar1,
  Navbar2: Navbar2,
  Pagination1: Pagination1,
  Pagination2: Pagination2,
  Pagination3: Pagination3,
  Pagination4: Pagination4,
  Tabs1: Tabs1,
  Tabs2: Tabs2,
  Tabs3: Tabs3,
  Tabs4: Tabs4,
  Timepicker1: Timepicker1,
  ProAlertJavaScript: ProAlertJavaScript,
  ProAlertReact: ProAlertReact,
  ProAlertAngular: ProAlertAngular,
  stepperJs1: StepperJs1,
  stepperJs2: StepperJs2,
  stepperJs3: StepperJs3,
};
