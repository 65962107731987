import React from 'react';

export const Dropdown1 = () => {
  return (
    <>
      <div class="dropdown">
        <button
          class="btn btn-light dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Dark Toggle Bar
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" href="#">
            This is the toggle menu
          </a>
        </div>
      </div>
    </>
  );
};

export const Dropdown2 = () => {
  return (
    <>
      <div class="dropdown">
        <button
          class="btn bg-primary btn-primary dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Primary Toggle Bar
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" href="#">
            This is the toggle menu with caret
          </a>
        </div>
      </div>
    </>
  );
};

export const Dropdown3 = () => {
  return (
    <>
      <div class="btn-group bg-primary dropright">
        <button
          class="btn btn-secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Secondary Toggle Bar as Nav
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" href="#">
            This is the toggle menu to the right
          </a>
        </div>
      </div>
    </>
  );
};

export const Dropdown4 = () => {
  return (
    <>
      <div class="btn-group bg-danger dropup">
        <button
          class="btn btn-danger dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Danger Toggle Bar
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" href="#">
            This is the toggle menu to the top
          </a>
        </div>
      </div>
    </>
  );
};

export const Dropdown5 = () => {
  return (
    <>
      <div class="btn-group bg-success dropleft">
        <button
          class="btn btn-success dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Success Toggle Bar
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" href="#">
            This is the toggle menu to the left
          </a>
        </div>
      </div>
    </>
  );
};

export const Dropdown6 = () => {
  return (
    <>
      <div class="container">
        <div class="dropdown">
          <button
            class="btn btn-light btn-lg dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Large Dark Toggle Bar
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li>
              <span class="dropdown-item">This is the toggle menu to the right</span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export const Dropdown7 = () => {
  return (
    <>
      <div class="container">
        <div class="dropdown dropright">
          <button
            class="btn btn-secondary bg-secondary btn-sm dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            small secondary toggle bar
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li>
              <span class="dropdown-item">cold place</span>
            </li>
            <div class="dropdown-divider"></div>
            <li>
              <span class="dropdown-item">First Item in Cold Place</span>
            </li>
            <li>
              <span class="dropdown-item">second</span>
            </li>
            <li>
              <span class="dropdown-item">second</span>
            </li>
            <li>
              <span class="dropdown-item">second</span>
            </li>
            <li>
              <span class="dropdown-item">
                <a href="/docs/contrast/react/components/alert">Alert</a>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export const Dropdown8 = () => {
  return (
    <>
      <div class="container">
        <div class="dropdown">
          <button
            class="btn btn-danger bg-danger dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Disabled Danger Toggle Bar
          </button>
        </div>
      </div>
    </>
  );
};

export const Dropdown9 = () => {
  return (
    <>
      <div class="container">
        <div class="dropdown">
          <button
            class="btn btn-secondary bg-secondary dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Dropdown
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li>
              <span class="dropdown-header">Cold place</span>
            </li>
            <li>
              <span class="dropdown-item">First Item in Cold Place</span>
            </li>
            <li>
              <span class="dropdown-item">second</span>
            </li>
            <li>
              <span class="dropdown-item">second</span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export const Dropdown10 = () => {
  return (
    <>
      <div class="container">
        <div class="dropdown dropleft">
          <button
            class="btn btn-warning bg-warning text-dark dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Dropdown
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li>
              <span class="dropdown-item">
                <a href="/docs/contrast/react/components/alert">Alert</a>
              </span>
            </li>
            <div class="dropdown-divider"></div>
            <li>
              <span class="dropdown-item">First Item in Cold Place</span>
            </li>
            <li>
              <span class="dropdown-item">second</span>
            </li>
            <li>
              <span class="dropdown-item">second</span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export const Dropdown11 = () => {
  return (
    <>
      <div class="container">
        <div class="dropdown dropup">
          <button
            class="btn btn-success bg-success dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Dropdown
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li>
              <span class="dropdown-item">Cold place</span>
            </li>
            <li>
              <span class="dropdown-item disabled">Disabled</span>
            </li>
            <li>
              <span class="dropdown-item">Second</span>
            </li>
            <li>
              <span class="dropdown-item">Last</span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export const Dropdown12 = () => {
  return (
    <>
      <div class="container">
        <div class="dropdown dropup">
          <button
            class="btn btn-dark bg-dark dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Dropdown
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li>
              <span class="dropdown-item">Cold place</span>
            </li>
            <li>
              <span class="dropdown-item active">Active</span>
            </li>
            <li>
              <span class="dropdown-item">Second</span>
            </li>
            <li>
              <span class="dropdown-item">Last</span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
